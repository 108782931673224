import React from "react";
import { useLocation } from "react-router-dom";
import TermsOfService from "../../Components/TermsOfService";
import PrivacyPolicy from "../../Components/PrivacyPolicy";
import DataProsAg from "../../Components/DataProsAg";
import DissLimLi from "../../Components/DissLimLi";
import CodeOfConduct from "../../Components/CodeOfConduct";
import AcceptableUse from "../../Components/AcceptableUse";

const LegalDetails = () => {
  const path = useLocation();
  const mypath = path.pathname.split("/")[2];

  return (
    <div>
      {mypath === "termsofservice" && <TermsOfService />}
      {mypath === "privacypolicy" && <PrivacyPolicy />}
      {mypath === "dataprocessingagreement" && <DataProsAg />}
      {mypath === "disclaimersandlimitationofliability" && <DissLimLi />}
      {mypath === "codeofconduct" && <CodeOfConduct />}
      {mypath === "acceptableuse" && <AcceptableUse />}
    </div>
  );
};

export default LegalDetails;
