import React from "react";
import { BrowserRouter as Router, Routes, Route, Link } from "react-router-dom";
import "./Assets/css/app.css";
import Login from "./Pages/Login";
import Legal from "./Pages/Legal/Legal";
import LegalDetails from "./Pages/LegalDetails/LegalDetails";

function App() {
  return (
    <Router basename="/">
      <div>
        <Routes class="container">
          <Route path="/legal" exact element={<Legal />} />
          <Route path="/legal/:title" element={<LegalDetails />} />
          <Route path="/" exact element={<Login />} />
        </Routes>
      </div>
    </Router>
    // <div className="App">
    //   <p>Hello</p>
    // </div>
  );
}

export default App;
